import { createSlice } from '@reduxjs/toolkit';
import lodash from 'lodash';
// utils
import { PlanState, Plan, Filter, Option, Person } from '../../../@types/carhub/plan';
//
import { dispatch } from '../../store';

import * as dateFns from 'date-fns';

import Decimal from 'decimal.js';

import Airtable from 'airtable';
Airtable.configure({
  endpointUrl: 'https://api.airtable.com',
  apiKey: 'key8ApUmUHPjOL2uW',
});
const base = Airtable.base('appXKRaKNRQRB1OLj');
// const base = Airtable.base('appRPO97wumgWyinj');

// ----------------------------------------------------------------------

const initialState: PlanState = {
  isLoading: false,
  error: null,
  filter: {
    store: '',
    checkInDate: '',
    checkInTime: '',
    checkOutDate: '',
    checkOutTime: '',
  },
  plans: [],
  // plan: null,
  plan: {
    id: '',
    name: '',
    limit: '',
    popularModel: '',
    price6h: '',
    price12h: '',
    price24h: '',
    priceOver1h: '',
    priceOver1d: '',
    priceHigh6h: '',
    priceHigh12h: '',
    priceHigh24h: '',
    priceHighOver1h: '',
    priceHighOver1d: '',
    thumbnail: '',
    price: 0,
    isHighSeason: false,
    diff: 0,
    diffDesc: '',
    optionPrice: {
      menseki: 0,
      anshin: 0,
    }
  },
  option: {
    delivery: 0,
    delivery2: 0,
    menseki: 0,
    anshin: 0,
    deliveryTime: '',
    delivery2Time: ''
  },
  person: {
    seiKana: '',
    meiKana: '',
    seiKanji: '',
    meiKanji: '',
    postCode: '',
    address: '',
    tel: '',
    mail: '',
    num: 0
  },
  stores: [],
  store: {
    id: '',
    name: '',
  },
};

const slice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET Plans
    getPlansSuccess(state, action) {
      state.isLoading = false;
      state.plans = action.payload;
    },

    setFilter(state, action) {
      state.filter.store = action.payload.store;
      state.filter.checkInDate = action.payload.checkInDate;
      state.filter.checkInTime = action.payload.checkInTime;
      state.filter.checkOutDate = action.payload.checkOutDate;
      state.filter.checkOutTime = action.payload.checkOutTime;
    },

    selectedPlan(state, action) {
      state.plan = action.payload;
    },

    setDeliveryOption(state, action) {
      state.option.delivery = action.payload;
    },

    setDelivery2Option(state, action) {
      state.option.delivery2 = action.payload;
    },

    setDeliveryTimeOption(state, action) {
      state.option.deliveryTime = action.payload;
    },

    setDelivery2TimeOption(state, action) {
      state.option.delivery2Time = action.payload;
    },

    setMensekiOption(state, action) {
      state.option.menseki = action.payload;
    },

    setAnshinOption(state, action) {
      state.option.anshin = action.payload;
    },

    setPerson(state, action) {
      state.person.seiKana = action.payload.seiKana;
      state.person.meiKana = action.payload.meiKana;
      state.person.seiKanji = action.payload.seiKanji;
      state.person.meiKanji = action.payload.meiKanji;
      state.person.postCode = action.payload.postCode;
      state.person.address = action.payload.address;
      state.person.tel = action.payload.tel;
      state.person.mail = action.payload.mail;
      state.person.num = action.payload.num;
    },

    getStoreSuccess(state, action) {
      state.isLoading = false;
      state.stores = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  setFilter,
  selectedPlan,
  setDeliveryOption,
  setDelivery2Option,
  setDeliveryTimeOption,
  setDelivery2TimeOption,
  setPerson,
  setAnshinOption,
  setMensekiOption,
} = slice.actions;

// ----------------------------------------------------------------------
export function getStores() {
  return async () => {
    try {
      const airStores = await base('店舗').select({}).all();

      console.log('airStores', airStores);
      const stores = airStores
        .map((x) => ({
          id: x.getId(),
          name: x.get('店舗名')?.toString(),
          sort: Number(x.get('sort')?.toString()),
        }))
        .sort((a, b) => {
          if (a.sort < b.sort) return -1;
          if (a.sort > b.sort) return 1;
          return 0;
        })
        ;
      dispatch(slice.actions.getStoreSuccess(stores));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function regist(plan: Plan, filter: Filter, option: Option, person: Person) {
  return async () => {
    const optionIds = []
    if (option.menseki === 1) {
      const result = await base('オプション料金').create({
        "オプション名": `免責補償（${plan.name}）`,
        "金額": plan.optionPrice.menseki
      })
      optionIds.push(result.id)
    }
    if (option.anshin === 1) {
      const result = await base('オプション料金').create({
        "オプション名": `安心パック（${plan.name}）`,
        "金額": plan.optionPrice.anshin
      })
      optionIds.push(result.id)
    }

    const customer = await base('お客様管理').create({
      "名前": `${person.seiKanji} ${person.meiKanji}`,
      "カナ": `${person.seiKana} ${person.meiKana}`,
      "住所": person.address,
      "電話番号": person.tel,
      "メールアドレス": person.mail,
    })

    const payment = await base('支払い管理').create({
      "基本料金": plan.price,
      "支払いタイミング": "貸渡時",
      "支払いステータス": "未払い",
    })

    const airStore = await base('店舗').select({}).all();
    const mapStore = airStore.map(x => ({
      id: x.id,
      name: x.get('店舗名')?.toString()
    }))
    .find(x => x.name === filter.store);

    const comment = () => {
      let sougeiMukae = ''
      if (option.delivery === 1) {
        sougeiMukae = '【お迎え】' + option.deliveryTime
      } else {
        sougeiMukae = '【お迎え】なし '
      }
  
      let sougeiOkuri = ''
      if (option.delivery2 === 1) {
        sougeiOkuri = '【お送り】' + option.delivery2Time
      } else {
        sougeiOkuri = '【お送り】なし '
      }
  
      return sougeiMukae + " " + sougeiOkuri
    }
    
    console.log('person', person)

    console.log('checkInDate', filter.checkInDate)
    console.log('checkOutDate', filter.checkOutDate)
    const reserv = await base('予約管理').create({
      "予約方法": [
        "recUg3gpF9ZVjwGI9"
      ],
      "店舗": [
        mapStore!.id
      ],
      "ステータス": "仮予約",
      "お客様": [
        customer.id
      ],
      "送迎": option.delivery !== 0 || option.delivery2 !== 0 ? "送迎あり" : "送迎なし",
      "出発日時": filter.checkInDate! + 'T' + filter.checkInTime + ':00.000Z',
      "返却日時": filter.checkOutDate! + 'T' + filter.checkOutTime + ':00.000Z',
      "レンタルタイプ": [
        plan.id
      ],
      "オプション": optionIds,
      "支払い": [
        payment.id
      ],
      "利用人数": Number(person.num),
      "コメント": comment(),
      "WEB": "1",
      "レンタル完了フラグ": 0
    })

    console.log('payment', payment.id)
    console.log('customer', customer.id)
    console.log('option', optionIds)
    console.log(reserv.id)

    // const test = await base('予約方法').select({}).all();
    // console.log(test)

  }
}

export function getPlans(store: string, checkIn: string, checkOut: string) {
  console.log(store, checkIn, checkOut);

  const checkInDate = dateFns.parseISO(checkIn);
  const checkOutDate = dateFns.parseISO(checkOut);
  // eslint-disable-next-line arrow-body-style
  const checkHighSeason = (baseStart: Date, baseEnd: Date, startDate: Date, endDate: Date) => {
    return (
      (baseStart.getTime() >= startDate.getTime() && baseStart.getTime() <= endDate.getTime()) ||
      (baseEnd.getTime() >= startDate.getTime() && baseEnd.getTime() <= endDate.getTime()) ||
      (baseStart.getTime() <= startDate.getTime() && baseEnd.getTime() >= endDate.getTime())
    );
  };


  const d = new Date(2022, 11)
  const isHighSeason =
    checkHighSeason(
      checkInDate,
      checkOutDate,
      new Date(checkInDate.getFullYear(), 3, 28),
      new Date(checkInDate.getFullYear(), 4, 7)
    ) ||
    checkHighSeason(
      checkInDate,
      checkOutDate,
      new Date(checkInDate.getFullYear(), 7, 8),
      new Date(checkInDate.getFullYear(), 7, 21)
    ) ||
    checkHighSeason(
      checkInDate,
      checkOutDate,
      new Date(checkInDate.getFullYear(), 8, 15),
      new Date(checkInDate.getFullYear(), 8, 25)
    ) ||
    checkHighSeason(
      checkInDate,
      checkOutDate,
      new Date(checkInDate.getFullYear(), 11, 28),
      new Date(checkInDate.getFullYear() + 1, 1, 3)
    );

  console.log(isHighSeason);

  const diff = (checkOutDate.getTime() - checkInDate.getTime()) / (1000 * 60 * 60);

  return async () => {
    dispatch(slice.actions.startLoading());

    try {
      // const airPlans = []
      const airPlans = await base('レンタルタイプ').select({}).all();

      console.log('airPlans', airPlans);

      const plans = airPlans
        .map((x) => {
          const thumbnail = x.get('サムネイル');
          let thumbnailUrl;
          if (!lodash.isEmpty(thumbnail)) {
            //TODO 無理やり
            const tmp: any = thumbnail;
            thumbnailUrl = tmp[0].url;
          }

          const data = {
            id: x.getId(),
            name: x.get('レンタルタイプ名')?.toString(),
            limit: x.get('乗車人数')?.toString(),
            popularModel: x.get('代表車種')?.toString(),
            price6h: !isHighSeason ? x.get('6h')?.toString() : x.get('h6h')?.toString(),
            price12h: !isHighSeason ? x.get('12h')?.toString() : x.get('h12h')?.toString(),
            price24h: !isHighSeason ? x.get('24h')?.toString() : x.get('h24h')?.toString(),
            priceOver1h: !isHighSeason ? x.get('over1h')?.toString() : x.get('hOver1h')?.toString(),
            priceOver1d: !isHighSeason ? x.get('over1d')?.toString() : x.get('hOver1d')?.toString(),

            priceHigh6h: x.get('h6h')?.toString(),
            priceHigh12h: x.get('h12h')?.toString(),
            priceHigh24h: x.get('h24h')?.toString(),
            priceHighOver1h: x.get('hOver1h')?.toString(),
            priceHighOver1d: x.get('hOver1d')?.toString(),

            thumbnail: thumbnailUrl,
            sort: Number(x.get('sort')?.toString()),
          };

          let price = 0;
          let diffDesc = '';
          if (diff <= 6) {
            diffDesc = '6時間';
            price = Number(data.price6h);
          } else if (diff <= 12) {
            diffDesc = '12時間';
            price = Number(data.price12h);
          } else if (diff <= 24) {
            diffDesc = '24時間';
            price = Number(data.price24h);
          } else {
            diffDesc = '24時間';
            price = Number(data.price24h);

            let overDiff = diff - 24;

            const overDay = Math.floor(overDiff / 24)
            const overHour = Math.ceil(overDiff % 24)

            console.log('----')
            console.log('name', data.name)
            console.log("day", overDay)
            console.log("mod", overHour)

            console.log("price1", price)
            price += (Number(data.priceOver1d) * overDay)
            console.log("price2", price)

            const tmpPrice = overHour * Number(data.priceOver1h);

            console.log('tmp', tmpPrice)
            if (tmpPrice > Number(data.priceOver1d)) {
              price += Number(data.priceOver1d);
            } else {
              price += tmpPrice;
            }

            const overDesc = (over: number, dayOrHour: string) => {
              if (over === 0) {
                return '';
              }
              return ` + 追加${over}${dayOrHour}`;
            };

            diffDesc += overDesc(overDay, '日') + overDesc(overHour, '時間');
          }

          const isBan = data.name === '1BOX・ミニバン';
          const modular24 = Math.ceil(diff / 24)

          const optionPrice = {
            menseki: isBan ? modular24 * 1430 : modular24 * 1100,
            anshin: isBan ? modular24 * 1320 : modular24 * 1100,
          }

          return { ...data, price, isHighSeason, diffDesc, diff, optionPrice };
        })
        .filter((x) => x.popularModel !== '未使用')
        .filter(
          (x) =>
            // TODO とりあえず年末年始は適用しない
            !checkHighSeason(
              checkInDate,
              checkOutDate,
              new Date(checkInDate.getFullYear(), 11, 28),
              new Date(checkInDate.getFullYear() + 1, 1, 3)
            )
        )
        .filter((x) => {
          if (store === '鹿児島空港店') {
            // return x.name === '小型車' || x.name === '中型車';
            return false
          }
          if (store === '屋久島店') {
            return x.name === '小型車' || x.name === '軽自動車';
          }
          return true;
        })
        .sort((a, b) => {
          if (a.sort < b.sort) return -1;
          if (a.sort > b.sort) return 1;
          return 0;
        });

      console.log('プラン', plans);
      // console.log("在庫", reservedStocks);

      const start = new Date(2022, 11, 26)
      const end = new Date(2023, 0, 6)
      if (start <= checkInDate && end >= checkInDate) {
        dispatch(slice.actions.getPlansSuccess([]));
      } else if (start <= checkOutDate && end >= checkOutDate) {
        dispatch(slice.actions.getPlansSuccess([]));
      } else {
        dispatch(slice.actions.getPlansSuccess(plans));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
