import { createSlice } from '@reduxjs/toolkit';
import lodash from 'lodash';
import sum from 'lodash/sum';
import uniqBy from 'lodash/uniqBy';
// utils
import axios from '../../utils/axios';
import { Reserv, ReservState } from '../../@types/reserv';
//
import { dispatch } from '../store';

import Airtable from 'airtable';
Airtable.configure({
  endpointUrl: 'https://api.airtable.com',
  apiKey: 'key8ApUmUHPjOL2uW',
});

const base = Airtable.base('appXKRaKNRQRB1OLj');
// const base = Airtable.base('appRPO97wumgWyinj');

// ----------------------------------------------------------------------

const initialState: ReservState = {
  isLoading: false,
  error: null,
  reservs: [],
  reserv: null,
  filters: {
    store: '',
    checkInDate: '',
    checkInTime: '',
    checkOutDate: '',
    checkOutTime: '',
  },
};

const slice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PRODUCTS
    getReservsSuccess(state, action) {
      state.isLoading = false;
      state.reservs = action.payload;
    },

    filterReservs(state, action) {
      state.filters.store = action.payload.store;
      state.filters.checkInDate = action.payload.checkInDate;
      state.filters.checkInTime = action.payload.checkInTime;
      state.filters.checkOutDate = action.payload.checkOutDate;
      state.filters.checkOutTime = action.payload.checkOutTime;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { filterReservs } = slice.actions;

// ----------------------------------------------------------------------

export function getReservs(stroe: string, checkIn: string, checkOut: string) {

  const type = "recNFEIRZhXJ6I1xw"
  const plan = "小型車"

  return async () => {
    dispatch(slice.actions.startLoading());
    console.log('getReservs');
    try {
      
      // const reservs = await base('予約管理')
      //   .select({
      //     // filterByFormula: `AND(IS_AFTER({出発日時}, '${checkIn}'), FIND({レンタルタイプ}, '${type}') > 0)`
      //     filterByFormula: `OR(
      //       AND(IS_AFTER({出発日時}, '${checkIn}'), IS_BEFORE({返却日時}, '${checkOut}')), 
      //       AND(IS_BEFORE({出発日時}, '${checkIn}'), IS_AFTER({返却日時}, '${checkOut}')),
      //       AND(IS_AFTER({出発日時}, '${checkIn}'), IS_BEFORE({返却日時}, '${checkOut}')),
      //       AND(IS_BEFORE({出発日時}, '${checkIn}'), IS_AFTER({返却日時}, '${checkOut}')))`
      //     // filterByFormula: `IS_AFTER({出発日時}, '${checkIn}')`
      //   })
      //   .all();
      
      // const stocks =  await base('在庫').select({
      // }).all()



      // const reservedStocks = reservs.map(x => x.get("貸出在庫"))

      // stocks.filter(x => {
      //   return reservedStocks.includes(x.get("車番")?.toString())
      // })

      const plans =  await base('レンタルタイプ').select({
      }).all()

      plans.map(x => {
        const thumbnail = x.get("サムネイル");
        if(!lodash.isEmpty(thumbnail)) {
          console.log("humbnail", thumbnail)
        }
      })
      console.log("予約管理", plans);
      // console.log("在庫", reservedStocks);

      // const response: { data: { reservs: Reserv[] } } = await axios.get('/api/products');
      // dispatch(slice.actions.getReservsSuccess(response.data.reservs));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
